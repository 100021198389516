import React from "react"

import * as C from "shared/components"

import * as S from "./styled"

export const BrandsTextarea = ({ name }) => (
  <S.BrandsTextarea>
    <S.BrandsTitle>Brands</S.BrandsTitle>
    <C.Textarea
      name={name}
      placeholder="Why do you want to motivate yourself? Actually, just answering that question fully can be one of the better ways to get your daily motivation."
      maxRows={3}
    />
  </S.BrandsTextarea>
)
