import React from "react"
import ErrorIcon from "shared/assets/error.svg"
import { Wrapper, Message } from "./styled"

export const DownloadErrorMessage = ({ show }) => {
  return (
    <Wrapper show={show}>
      <ErrorIcon />
      <Message>
        An error occurred while sending an email. Please try again
      </Message>
    </Wrapper>
  )
}
