import styled from "styled-components"

export const MainInfoWrapper = styled.div`
  width: calc(100% + 2.5rem);
  height: 36.3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  top: -1.25rem;
  left: -1.25rem;
  border-top-left-radius: 0.6rem;
  border-top-right-radius: 0.6rem;
  overflow: hidden;

  &:before {
    content: ${props => (props.isPhotoUploaded ? "''" : "none")};
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    pointer-events: none;
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 61%,
      rgba(0, 0, 0, 0.48)
    );
  }
`

export const SectionTitle = styled.div`
  height: 1.87rem;
  margin-bottom: 1.25rem;
  display: flex;
  justify-content: space-between;
  position: relative;
`

export const Title = styled.div`
  display: flex;
  align-items: center;
`

export const OverallInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1.19rem;
`

export const InfoRow = styled.div`
  display: flex;
  height: 1.8rem;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.62rem;
`

export const UploadView = styled.div`
  display: ${props => (props.isVisible ? "flex" : "none")};
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const ImageView = styled.div`
  display: ${props => (props.isVisible ? "block" : "none")};
  width: 20rem;
  height: 36.3rem;
`

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 1.25rem;
  left: 1.25rem;
  z-index: 1;

  & input {
    color: ${props =>
      props.isLight ? props.theme.color.white : props.theme.color.black};
  }
  & input::placeholder {
    color: ${props =>
      props.isLight ? props.theme.color.white : props.theme.color.lightGrey};
  }
`

export const Image = styled.img`
  height: 100%;
  object-fit: cover;
  position: relative;
  z-index: 0;
`

export const Wrapper = styled.div`
  width: 670px;
  height: 430px;
  margin-bottom: 1.8rem;
  display: flex;
  justify-content: start;
  align-content: space-between;
  flex-wrap: wrap;
`

export const TextAreaTitle = styled.div`
  margin-bottom: 0.6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
`
export const FlexDiv = styled.div`
  display: flex;
  align-items: center;
`

export const Error = styled.div`
  font-size: ${props => props.theme.font.size.xs};
  color: ${props => props.theme.color.red};
  margin-left: 1.6rem;
  margin-top: 0.5rem;
  font-family: ${props => props.theme.font.family.robotoRegular};
  position: absolute;
  top: 3.2rem;
  left: 0;
`

export const TextAreaError = styled.div`
  font-size: 0.64rem;
  color: #ff001a;
  margin-left: 0.85rem;
  font-family: ${props => props.theme.font.family.robotoRegular};
  position: absolute;
  bottom: 1.2rem;
  left: 0;
`

export const BrandsContainer = styled.div`
  display: flex;
  margin-top: 0.3rem;
`
