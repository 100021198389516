import styled from "styled-components"

export const TitleInput = styled.div`
  position: relative;
  width: 100%;
  margin-right: 0.5rem;
  max-width: 32.5rem;
`

export const StyledInput = styled.input`
  ${props => props.theme.typography.sourceSansPro.font240};
  display: block;
  width: 100%;
  height: 3.4375rem;
  padding: 0 1.875rem;
  border: 1px solid
    ${({ isError, theme }) =>
      isError ? theme.color.red : theme.color.paleGrey};
  border-radius: 1.72rem;
  box-shadow: ${props => props.theme.elevation.shadow100};
  color: ${props => props.theme.color.darkGray};
  transition: border-color ${props => props.theme.animation.fast};

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-text-fill-color: ${props => props.theme.color.black};
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
  }

  &:focus {
    outline: none;
  }
  &::placeholder {
    ${props => props.theme.typography.sourceSansPro.font240};
    color: ${props => props.theme.color.midGray};
  }
`

export const ErrorLabel = styled.p`
  ${props => props.theme.typography.sourceSansPro.font140};
  color: ${props => props.theme.color.red};
  margin: 0;
  position: absolute;
  top: calc(100% + 0.125rem);
  left: 2rem;
  opacity: 1;
  transition: opacity ${props => props.theme.animation.fast};

  &:empty {
    opacity: 0;
  }
`
