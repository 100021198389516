import styled from "styled-components"

export const Input = styled.input`
  width: 100%;
  ${props => props.theme.typography.sourceSansPro.font360}
  color: ${props => props.theme.color.black};
  outline: none;
  border: none;
  background-color: transparent;
  text-align: right;
  white-space: nowrap;
  overflow: hidden;
  margin-left: 1rem;

  &:-webkit-autofill,
  &:-webkit-autofill:hover, 
  &:-webkit-autofill:focus {
    -webkit-text-fill-color: ${props => props.theme.color.black};
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
  }

  &:focus {
    outline: none;
  }
  &:focus::placeholder {
    color: transparent;
  }
  &::placeholder {
    ${props => props.theme.typography.sourceSansPro.font340}
    color: ${props => props.theme.color.lightGrey};
    text-overflow: ellipsis;
  }
  &::-moz-placeholder {
    opacity: 1;
  }
 
`
