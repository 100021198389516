import styled from "styled-components"

import * as C from "shared/components"

export const Root = styled(C.ContentWrapper)`
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
`

export const RightContainer = styled.div`
  width: 63.7rem;
  min-height: 56rem;
  max-width: 75%;
  margin-left: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: space-between;
  flex-wrap: wrap;
`

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: space-between;
  max-width: 100%;

  & + & {
    margin-top: 1.875rem;
  }
`
