import React from "react"

import * as C from "shared/components"

import * as S from "./styled"

export const CharacteristicsSection = ({ characteristics }) => (
  <S.CharacteristicsSectionRoot>
    {characteristics.map(characteristic => (
      <C.Card
        key={characteristic.id}
        width="13.5rem"
        height="13.1rem"
        size="sm"
        title={characteristic.title}
        dotColor={characteristic.color}
        overrides={{
          ":not(:first-child):not(:nth-child(4))": {
            marginLeft: "0.6rem",
          },
        }}
        isPrintMode
      >
        <C.Textarea {...characteristic} isPrintMode />
      </C.Card>
    ))}
  </S.CharacteristicsSectionRoot>
)
