import React from "react"

import * as S from "./styled"

export const DetailsRow = ({ name, value }) => (
  <S.DetailsRow>
    <S.DetailsRowName>{name}</S.DetailsRowName>
    <S.DetailsRowValue>{value}</S.DetailsRowValue>
  </S.DetailsRow>
)
