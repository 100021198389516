import React from "react"

import * as C from "shared/components"

import * as S from "./styled"

export const BrandsTextarea = props => (
  <S.BrandsTextarea>
    <S.BrandsTitle>Brands</S.BrandsTitle>
    <C.Textarea {...props} isPrintMode />
  </S.BrandsTextarea>
)
