import React from "react"

import * as SC from "./sections"
import * as S from "./styled"

export const PersonaForm = ({
  photo,
  openModal,
  savePersona,
  isPhotoUploaded,
  setIsPhotoUploaded,
}) => (
  <S.Root>
    <SC.PhotoSection
      photo={photo}
      isPhotoUploaded={isPhotoUploaded}
      setIsPhotoUploaded={setIsPhotoUploaded}
    />
    <S.RightContainer>
      <SC.TopSection openModal={openModal} savePersona={savePersona} />
      <S.Row>
        <SC.SkillsSection />
        <SC.CharacteristicsSection />
      </S.Row>
      <S.Row>
        <SC.ReachSection />
        <SC.MotivationToBuySection />
        <SC.TrendsSection />
      </S.Row>
    </S.RightContainer>
  </S.Root>
)
