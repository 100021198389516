import React from "react"

import * as C from "shared/components"

export const MotivationToBuySection = ({ motivations }) => (
  <C.Card
    width="20rem"
    title="Motivation to buy"
    dotColor="lightGreen"
    isPrintMode
  >
    {motivations.map(({ id, label, ...r }) => (
      <C.Slider key={id} {...r} name={label} isPrintMode />
    ))}
  </C.Card>
)
