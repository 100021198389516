import styled from "styled-components"

import EditIcon from "shared/assets/edit.svg"

export const StyledButtonEdit = styled.div`
  width: 2.3rem;
  height: 2.3rem;
  opacity: 0.25;
  border-radius: 50%;
  cursor: pointer;
  background: ${props => props.theme.color.totalBlack};
  position: absolute;
  top: 1.2rem;
  right: 1.2rem;
  z-index: 10;
  outline: none;
  transition: opacity ${props => props.theme.animation.fast};

  &:hover {
    opacity: 0.5;
    box-shadow: ${props => props.theme.boxShadow.smallButtonShadow};
  }
`

export const Label = styled.label`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
`

export const IconEdit = styled(EditIcon)`
  width: 1.1rem;
  height: 1.1rem;
  fill: ${props => props.theme.color.white};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const FileInput = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`
