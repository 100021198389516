import styled from "styled-components"

export const DetailsRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 1.5rem auto 0;
`

export const DetailsRowName = styled.p`
  ${props => props.theme.typography.sourceSansPro.font440};
  color: ${props => props.theme.color.midGray};
  line-height: 1;
`

export const DetailsRowValue = styled.p`
  ${props => props.theme.typography.sourceSansPro.font460};
  color: ${props => props.theme.color.black};
  line-height: 1;
`
