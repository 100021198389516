import React from "react"
import { connect, FieldArray } from "formik"

import * as C from "shared/components"
import * as O from "components/persona/constants"

import * as CSC from "./components"
import * as S from "./styled"

export const CharacteristicsSection = connect(({ formik }) => {
  const { characteristics } = formik.values
  const characteristicsOptions = O.characteristicsOptions.filter(
    co => !characteristics.find(c => c.id === co.id)
  )

  return (
    <S.CharacteristicsSectionRoot>
      <FieldArray
        name="characteristics"
        render={arrayHelpers => {
          const addAction = id => {
            const newItem = O.characteristicsOptions.find(o => o.id === id)
            const duplicatedItem = characteristics.find(c => c.id === id)

            if (newItem && !duplicatedItem) {
              arrayHelpers.push({ ...newItem, value: "" })
            }
          }

          return (
            <>
              {characteristics.map((characteristic, index) => (
                <C.Card
                  key={characteristic.id}
                  width="13.5rem"
                  height="13.1rem"
                  maxWidth="33.33%"
                  size="sm"
                  title={characteristic.title}
                  dotColor={characteristic.color}
                  removeAction={() => {
                    arrayHelpers.remove(index)
                    formik.submitForm()
                  }}
                  overrides={{
                    ":not(:first-child):not(:nth-child(4))": {
                      marginLeft: "0.6rem",
                      maxWidth: "calc(33.33% - 0.6rem)",
                    },
                  }}
                >
                  <C.Textarea
                    name={`characteristics[${index}].value`}
                    placeholder={characteristic.placeholder}
                  />
                </C.Card>
              ))}
              {characteristics.length < 6 && (
                <CSC.NewCharacteristicsBox
                  options={characteristicsOptions}
                  addAction={addAction}
                />
              )}
            </>
          )
        }}
      />
    </S.CharacteristicsSectionRoot>
  )
})
