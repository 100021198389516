import axios from "axios"

export const generateAndSendPdf = async (
  personaDetails,
  userDetails,
  printContainer
) => {
  try {
    if (typeof window !== "undefined") {
      const JsPDF = require("jspdf")
      const html2canvas = require("html2canvas")

      const canvas = await html2canvas(printContainer)
      const imgData = canvas.toDataURL("image/png")

      const pdf = new JsPDF("p", "pt", "a4")
      pdf.addImage(imgData, "PNG", 25, 25, 565, 790, undefined, "FAST")
      const result = btoa(pdf.output())
      await axios.post(`${process.env.GATSBY_API_URL}/personas`, {
        ...userDetails,
        pdf: result,
      })
    }
    return { success: true }
  } catch (error) {
    return {
      success: false,
      error: error.message,
    }
  }
}
