import React from "react"
import styled from "styled-components"

import AvatarIcon from "shared/assets/black-male-user-symbol.svg"

const StyledAvatar = styled.div`
  width: 7.25rem;
  height: 7.25rem;
  border-radius: 50%;
  background: ${props => props.theme.color.paleGrey};
  position: relative;
`

const IconAvatar = styled(AvatarIcon)`
  width: 6.25rem;
  height: 6.25rem;
  fill: ${props => props.theme.color.avatarGray};
  position: absolute;
  top: 1.2rem;
  left: 0.5rem;
`

export const Avatar = () => (
  <StyledAvatar>
    <IconAvatar />
  </StyledAvatar>
)
