import styled from "styled-components"
import PlusIcon from "shared/assets/add-plus-button.svg"
import * as C from "shared/components"

export const Root = styled.header`
  background-color: ${props => props.theme.color.white};
`

export const LogoWrapper = styled.div`
  height: 4.25rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

export const AddIcon = styled(PlusIcon)`
  width: 0.625rem;
  height: 0.625rem;
  fill: ${props => props.theme.color.darkGray};
  margin-left: 0.875rem;
  vertical-align: middle;
`

export const HeaderWrapper = styled(C.ContentWrapper)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
