import React, { useRef, useState } from "react"
import PropTypes from "prop-types"
import { Field } from "formik"

import * as S from "./styled"

const createOption = label => ({ label, value: label })

export const MultiValue = ({ name, variant }) => {
  const tagAreaRef = useRef()
  const [inputValue, setInputValue] = useState("")

  return (
    <S.TagAreaWrapper ref={tagAreaRef}>
      <Field name={name}>
        {({ field, form }) => {
          const handleKeyDown = event => {
            const tags = field.value
            const tagAreaHeight = tagAreaRef.current.clientHeight + 1

            if (["Enter", "Tab"].includes(event.key)) {
              const newTags = tags ? [...tags] : []
              const trimmedValue = inputValue.trim()
              if (!trimmedValue) {
                return
              }

              if (tags != null && tags.length > 1) {
                const duplicatedTag = tags.find(tag => tag.value === inputValue)
                if (duplicatedTag || tagAreaHeight > 174) {
                  setInputValue("")
                  return
                }
              }

              newTags.push(createOption(trimmedValue))

              form.setFieldValue(field.name, newTags)
              form.submitForm()
              setInputValue("")

              event.preventDefault()
            }
          }

          return (
            <S.StyledCreatable
              components={{ DropdownIndicator: null }}
              inputValue={inputValue}
              onInputChange={setInputValue}
              value={field.value}
              onBlur={form.submitForm}
              onChange={value => form.setFieldValue(field.name, value)}
              onKeyDown={handleKeyDown}
              variant={variant}
              isClearable={false}
              menuIsOpen={false}
              isMulti
            />
          )
        }}
      </Field>
    </S.TagAreaWrapper>
  )
}

MultiValue.defaultProps = {
  variant: "positive",
}

MultiValue.propTypes = {
  name: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(["positive", "negative"]),
}
