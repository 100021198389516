import React from "react"
import { connect } from "formik"

import { localStorageKey } from "components/persona/constants"
import * as C from "shared/components"

import * as S from "./styled"

export const Header = connect(({ resetInitialValues, formik }) => (
  <S.Root>
    <S.HeaderWrapper>
      <S.LogoWrapper>
        <C.Logo />
      </S.LogoWrapper>

      <C.Button
        size="xl"
        elevation={1}
        elevationStep={1}
        type="submit"
        color="grey"
        overrides={{
          minWidth: "10.625rem",
          padding: "0 1.5rem",
          height: "2.75rem",
          lineHeight: "2.75rem",
        }}
        onClick={() => {
          localStorage.removeItem(localStorageKey)
          resetInitialValues()
          formik.resetForm()
        }}
      >
        Create New
        <S.AddIcon />
      </C.Button>
    </S.HeaderWrapper>
  </S.Root>
))
