import React, { useRef, useEffect } from "react"
import { connect } from "formik"

import { Description, DescriptionTitle } from "shared/styles/typography"

import * as C from "shared/components"

import * as PSC from "./components"

import {
  Image,
  ImageView,
  InfoRow,
  InputWrapper,
  MainInfoWrapper,
  OverallInfoWrapper,
  UploadView,
} from "./styled"

import { dropdownOptions } from "./options"

export const PhotoSection = connect(
  ({ formik, photo, setIsPhotoUploaded, isPhotoUploaded }) => {
    const imageRef = useRef()
    const { setFieldValue, submitForm } = formik

    useEffect(() => {
      if (!photo) {
        return
      }

      const image = imageRef.current
      image.src = photo
      setIsPhotoUploaded(true)
      // eslint-disable-next-line
    }, [photo])

    /* Prepare file reader. */
    let reader
    if (typeof window !== "undefined") {
      reader = new FileReader()
    }

    const readImage = () => {
      const image = imageRef.current
      reader.addEventListener(
        "load",
        () => {
          image.src = reader.result
          setFieldValue("photo", reader.result)
          submitForm()
        },
        false
      )
    }

    const handleChange = id => {
      const file = document.getElementById(id).files[0]
      readImage()

      if (file) {
        setIsPhotoUploaded(true)
        reader.readAsDataURL(file)
      }
    }

    return (
      <C.Card width="20rem" minHeight="56rem" maxWidth="25%" noContentWrapper>
        <MainInfoWrapper isPhotoUploaded={isPhotoUploaded}>
          <UploadView isVisible={!isPhotoUploaded}>
            <PSC.Avatar />
            <Description>Upload persona photo</Description>
            <PSC.Upload onChange={() => handleChange("file")} />
          </UploadView>

          <ImageView isVisible={isPhotoUploaded}>
            <PSC.EditButton onChange={() => handleChange("fileEdit")} />
            <Image ref={imageRef} src="" alt="persona image" />
          </ImageView>
          <InputWrapper isLight={isPhotoUploaded}>
            <PSC.TopInfoInput name="fullName" placeholder="Full Name" />
            <PSC.TopInfoInput name="occupation" placeholder="Occupation" />
            <PSC.TopInfoInput name="age" placeholder="Age" />
          </InputWrapper>
        </MainInfoWrapper>

        <OverallInfoWrapper>
          <InfoRow>
            <DescriptionTitle>Sex</DescriptionTitle>
            <PSC.Dropdown name="sex" options={dropdownOptions.sex} />
          </InfoRow>
          <InfoRow>
            <DescriptionTitle>Location</DescriptionTitle>
            <PSC.InvisibleInput
              name="location"
              placeholder="Provide location"
            />
          </InfoRow>
          <InfoRow>
            <DescriptionTitle>Company size</DescriptionTitle>
            <PSC.Dropdown
              name="companySize"
              options={dropdownOptions.companySize}
            />
          </InfoRow>
          <InfoRow>
            <DescriptionTitle>Industry</DescriptionTitle>
            <PSC.InvisibleInput
              name="industry"
              placeholder="Provide industry"
            />
          </InfoRow>
          <InfoRow>
            <DescriptionTitle>Education</DescriptionTitle>
            <PSC.Dropdown
              name="education"
              options={dropdownOptions.education}
            />
          </InfoRow>
          <InfoRow>
            <DescriptionTitle>Family status</DescriptionTitle>
            <PSC.Dropdown
              name="familyStatus"
              options={dropdownOptions.familyStatus}
            />
          </InfoRow>
        </OverallInfoWrapper>
      </C.Card>
    )
  }
)
