import { useReducer } from "react"

import * as C from "../constants"

const initialState = {
  isModalOpen: false,
  isModalLoading: false,
  personaDetails: C.initialValues,
  error: null,
}

function reducer(state, action) {
  switch (action.type) {
    case "savePersonaDetails":
      return {
        ...initialState,
        personaDetails: action.personaDetails,
        isModalOpen: true,
      }
    case "savePersonaNoModal":
      return {
        ...initialState,
        personaDetails: action.personaDetails,
      }
    case "closeModal":
      return { ...state, isModalOpen: false, error: null }
    case "openModal":
      return { ...state, isModalOpen: true }
    case "receiveError":
      return { ...state, error: action.error }
    default:
      throw new Error()
  }
}

export function usePersonaState() {
  const [state, dispatch] = useReducer(reducer, initialState)

  return {
    isModalOpen: state.isModalOpen,
    isModalLoading: state.isModalLoading,
    personaDetails: state.personaDetails,
    error: state.error,
    savePersonaDetails: personaDetails =>
      dispatch({ type: "savePersonaDetails", personaDetails }),
    savePersonaNoModal: personaDetails =>
      dispatch({ type: "savePersonaNoModal", personaDetails }),
    openModal: () => dispatch({ type: "openModal" }),
    closeModal: () => dispatch({ type: "closeModal" }),
    receiveError: error => dispatch({ type: "receiveError", error }),
  }
}
