import styled from "styled-components"

const getInputOverrides = props => {
  switch (props.name) {
    case "fullName":
      return { ...props.theme.typography.sourceSansPro.font660 }
    case "occupation":
      return { ...props.theme.typography.sourceSansPro.font540 }
    default:
      return {
        ...props.theme.typography.sourceSansPro.font440,
        margin: "1rem auto 1.25rem",
      }
  }
}

export const Input = styled.input`
  width: 100%;
  outline: none;
  border: none;
  background-color: transparent;
  ${getInputOverrides}

  &:-webkit-autofill,
  &:-webkit-autofill:hover, 
  &:-webkit-autofill:focus {
    -webkit-text-fill-color: ${props => props.theme.color.white};
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
  }

  &:focus {
    outline: none;
  }
  &:focus::placeholder {
    color: transparent;
  }
  &::placeholder {
    ${props => props.theme.typography.sourceSansPro.font340}
    ${getInputOverrides}
  }
  &::-moz-placeholder {
    opacity: 1;
  }
`
