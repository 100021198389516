import React from "react"
import PropTypes from "prop-types"

import * as S from "./styled"

export const EditButton = ({ onChange }) => (
  <S.StyledButtonEdit>
    <S.Label htmlFor="fileEdit">
      <S.IconEdit />
      <S.FileInput
        id="fileEdit"
        type="file"
        name="fileEdit"
        onChange={onChange}
      />
    </S.Label>
  </S.StyledButtonEdit>
)

EditButton.propTypes = {
  onChange: PropTypes.func.isRequired,
}
