import styled from "styled-components"
import Select from "react-select"

export const Arrow = styled.span`
  position: absolute;
  top: 50%;
  right: 0.5rem;
  transform: translate(50%, -50%);
  width: 0;
  height: 0;
  z-index: 1;
  border-left: 0.25rem solid transparent;
  border-right: 0.25rem solid transparent;
  border-top: 0.25rem solid ${props => props.theme.color.midGray};
  opacity: 1;
  transition: opacity ${props => props.theme.animation.fast};
  pointer-events: none;
`

export const StyledDropdown = styled(Select)`
  cursor: pointer;
  width: 100%;

  &:hover ${Arrow} {
    opacity: 0.7;
  }

  & div[class*="-IndicatorsContainer"] {
    & > div:first-child {
      display: none;
    }
  }
  & span[class*="-indicatorSeparator"] {
    display: none;
  }
  & div[class*="-indicatorContainer"] {
    padding: 0;
    & > div:first-child {
      display: none;
    }
  }

  & div[class*="-control"] {
    border: none;
    box-shadow: none;
    width: 100%;
    padding-right: 1rem;
    cursor: pointer;

    & > div:first-child {
      display: flex;
      justify-content: flex-end;
    }
    & > div:nth-child(2) {
      & > div:first-child {
        display: none;
      }
    }
  }
  & div[class*="-placeholder"] {
    display: none;
  }
  & div[class*="-singleValue"] {
    ${props => props.theme.typography.sourceSansPro.font360};
  }
  & div[class*="-menu"] {
    box-shadow: ${props => props.theme.boxShadow.dropdownShadow};
    border-radius: 0.4rem;
    min-width: 7.5rem;
    max-width: max-content;
    right: 0;
    z-index: 100;
  }

  & div[class*="-option"] {
    ${props => props.theme.typography.sourceSansPro.font240};
    color: ${props => props.theme.color.black};
    background: ${props => props.theme.color.white};
    transition: background ${props => props.theme.animation.fast};
    cursor: pointer;

    &:hover {
      background: ${props => props.theme.color.paleGrey};
    }
  }

  & div[class*="-Input"] {
    & > div:first-child {
      color: transparent;
    }
  }
`
