import React, { useState } from "react"

import * as S from "./styled"

export const NewCharacteristicsBox = React.memo(({ options, addAction }) => {
  const [isClicked, setIsClicked] = useState(false)

  const onSelectAction = id => {
    addAction(id)
    setIsClicked(false)
  }

  if (isClicked) {
    return (
      <S.BoxContainer>
        <S.SelectBox>
          {options.map(o => {
            return (
              <S.SelectElement onClick={() => onSelectAction(o.id)} key={o.id}>
                <S.SelectElementTitle dotColor={o.color}>
                  {o.title}
                </S.SelectElementTitle>
              </S.SelectElement>
            )
          })}
        </S.SelectBox>
      </S.BoxContainer>
    )
  }

  return (
    <S.BoxContainer>
      <S.NewBox onClick={() => setIsClicked(true)}>
        <S.IconCircleWrapper>
          <S.IconAdd />
          <S.NewBoxActionName>New item</S.NewBoxActionName>
        </S.IconCircleWrapper>
      </S.NewBox>
    </S.BoxContainer>
  )
})
