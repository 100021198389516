import styled from "styled-components"

export const Wrapper = styled.div`
  background-color: rgba(255, 0, 0, 0.1);
  border-radius: 4px;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  transition: opacity 0.3s;
  opacity: ${props => (props.show ? 1 : 0)};
  & svg {
    display: block;
    width: 16px;
    height: 16px;
    margin-right: 10px;
  }
`

export const Message = styled.span`
  display: block;
  ${props => props.theme.typography.ttCommons.font240};
  color: ${props => props.theme.color.reddish};
`
