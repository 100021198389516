import React from "react"
import { FastField } from "formik"

import { theme } from "shared/styles/theme"
import * as S from "./styled"

export const Input = ({
  name,
  isSelect = false,
  options,
  placeholder,
  ...props
}) => (
  <S.Input>
    <FastField name={name}>
      {({ field, form }) => {
        const error = form.errors[field.name]
        const isTouched = form.touched[field.name]

        return (
          <>
            <S.StyledInput
              as={isSelect ? "select" : ""}
              placeholder={placeholder}
              data-lpignore="true"
              isError={isTouched && !!error}
              {...field}
              {...props}
              style={{
                color: field.value === "" ? theme.color.midGray : "",
                cursor: isSelect ? "pointer" : "",
              }}
            >
              {isSelect ? (
                <>
                  <option disabled value="">
                    Role
                  </option>

                  {options.map(option => (
                    <option value={option.value} key={option.id}>
                      {option.label}
                    </option>
                  ))}
                </>
              ) : null}
            </S.StyledInput>
            {isSelect && <S.SelectMarker />}

            <S.ErrorLabel>{isTouched ? error : ""}</S.ErrorLabel>
          </>
        )
      }}
    </FastField>
  </S.Input>
)
