import styled from "styled-components"

export const BrandsTextarea = styled.div`
  margin: 1.5rem auto 0.5rem;
`

export const BrandsTitle = styled.p`
  ${props => props.theme.typography.sourceSansPro.font260};
  line-height: 1;
  color: ${props => props.theme.color.darkGray};
  margin: 0 auto 0.5rem;
`
