import React from "react"

import * as C from "shared/components"

export const SkillsSection = ({ skills }) => (
  <C.Card width="20rem" title="Skills" dotColor="yellow" isPrintMode>
    {skills.map(skill => (
      <C.Slider {...skill} isPrintMode />
    ))}
  </C.Card>
)
