import React from "react"

import { DetailsRow } from "./DetailsRow"

export const Details = ({
  sex,
  location,
  companySize,
  industry,
  education,
  familyStatus,
}) => (
  <div>
    {sex && <DetailsRow name="Sex" value={sex} />}
    {location && <DetailsRow name="Location" value={location} />}
    {companySize && <DetailsRow name="Company size" value={companySize} />}
    {industry && <DetailsRow name="Industry" value={industry} />}
    {education && <DetailsRow name="Education" value={education} />}
    {familyStatus && <DetailsRow name="Family status" value={familyStatus} />}
  </div>
)
