import React from "react"
import PropTypes from "prop-types"
import { FastField } from "formik"

import * as S from "./styled"

export const TopInfoInput = ({ name, placeholder }) => (
  <FastField name={name}>
    {({ field, form: { submitForm } }) => (
      <S.Input
        {...field}
        type="text"
        placeholder={placeholder}
        data-lpignore="true"
        onBlur={submitForm}
      />
    )}
  </FastField>
)

TopInfoInput.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
}
