import React from "react"

import * as SC from "./sections"
import * as C from "./components"
import * as S from "./styled"

export const PrintView = React.forwardRef(
  (
    {
      title,
      photo,
      fullName,
      occupation,
      age,
      skills,
      characteristics,
      reach,
      motivations,
      positiveTrends,
      negativeTrends,
      ...rest
    },
    ref
  ) => (
    <S.PrintViewRoot>
      <S.PrintViewContent ref={ref}>
        <S.Title>{title}</S.Title>
        <S.Container>
          <S.LeftColumn>
            {photo ? (
              <S.PhotoContainer>
                <S.Photo src={photo} alt="Persona image" />
              </S.PhotoContainer>
            ) : (
              <S.PhotoOutline />
            )}
            <S.FullName greyed={!fullName}>
              {fullName || "Name unspecified"}
            </S.FullName>
            <S.Occupation greyed={!occupation}>
              {occupation || "Occupation unspecified"}
            </S.Occupation>
            <S.Age greyed={!age}>{age || "Age unspecified"}</S.Age>
            <C.Details {...rest} />
          </S.LeftColumn>
          <div>
            {/* TODO: Improve this layout when the design will be ready. */}
            <S.TopRow>
              <SC.SkillsSection skills={skills} />
              <SC.CharacteristicsSection characteristics={characteristics} />
            </S.TopRow>
            <S.BottomRow>
              <SC.ReachSection reach={reach} />
              <SC.MotivationToBuySection motivations={motivations} />
              <SC.TrendsSection
                positiveTrends={positiveTrends}
                negativeTrends={negativeTrends}
              />
            </S.BottomRow>
          </div>
        </S.Container>
        <S.LogoSection>
          <S.CreatorLogo />
        </S.LogoSection>
      </S.PrintViewContent>
    </S.PrintViewRoot>
  )
)
