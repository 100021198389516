import React from "react"
import PropTypes from "prop-types"
import { FastField } from "formik"
import { components } from "react-select"

import * as S from "./styled"

export const Dropdown = ({ name, ...props }) => {
  return (
    <FastField name={name}>
      {({ field, form }) => {
        const selectedOption = props.options
          ? props.options.find(option => option.value === field.value)
          : ""

        const onSelectChange = e => {
          if (e && e.target && e.target.value) {
            form.setFieldValue(field.name, e.target.value)
            form.submitForm()
            return
          }

          form.setFieldValue(field.name, e.value)
          form.submitForm()
        }

        return (
          <S.StyledDropdown
            {...props}
            {...field}
            value={selectedOption || ""}
            onChange={onSelectChange}
            components={{
              Control: controlProps => (
                <>
                  <S.Arrow />
                  <components.Control {...controlProps} />
                </>
              ),
              DownChevron: null,
              DropdownIndicator: null,
            }}
          />
        )
      }}
    </FastField>
  )
}

Dropdown.defaultProps = {
  placeholder: null,
  value: "",
  options: [],
}
Dropdown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.string, label: PropTypes.string })
  ),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
}
