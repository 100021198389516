import React from "react"

import * as C from "shared/components"

import * as TC from "./components"
import * as S from "./styled"

export const TrendsSection = () => {
  return (
    <C.Card width="20rem" maxWidth="32%" title="Trends" dotColor="lavender">
      <S.Subtitle>Negative trends</S.Subtitle>
      <TC.MultiValue name="negativeTrends" variant="negative" />
      <S.Subtitle>Positive trends</S.Subtitle>
      <TC.MultiValue name="positiveTrends" />
    </C.Card>
  )
}
