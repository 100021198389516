import styled from "styled-components"
import SDHLogoSvg from "shared/assets/sdh-logo.svg"
import CreatorLogoHorizontal from "shared/assets/persona-logo.svg"

export const PrintViewRoot = styled.div`
  position: absolute;
  top: 0;
  left: -1000%;
  overflow: hidden;
`

export const PrintViewContent = styled.div`
  width: 1440px;
  height: 2037px;
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
`

export const Title = styled.h1`
  ${props => props.theme.typography.sourceSansPro.font440};
  color: ${props => props.theme.color.darkGrey};
  margin: 0 auto 4.625rem;
`

export const Container = styled.div`
  display: flex;
`

export const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
`

export const BottomRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
`

export const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 21.25rem;
  padding-right: 2.5rem;
  box-sizing: content-box;
`

export const Photo = styled.img`
  width: 100%;
  object-fit: cover;
`

export const PhotoContainer = styled.div`
  width: 21.25rem;
  height: 21.25rem;
  margin-bottom: 2.5rem;
  border-radius: 1.125rem;
  overflow: hidden;
`

export const PhotoOutline = styled.div`
  width: 21.25rem;
  height: 21.25rem;
  object-fit: cover;
  border: 1px solid ${props => props.theme.color.darkGrey};
  border-radius: 1.125rem;
  margin-bottom: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const FullName = styled.p`
  ${props => props.theme.typography.sourceSansPro.font660};
  color: ${props => props.theme.color.darkGrey};
  line-height: 1;
  margin: 0 auto;
  opacity: ${props => (props.greyed ? 0.3 : 1)};
`

export const Occupation = styled.p`
  ${props => props.theme.typography.sourceSansPro.font540};
  color: ${props => props.theme.color.darkGrey};
  line-height: 1;
  margin: 0.5rem auto 0;
  opacity: ${props => (props.greyed ? 0.3 : 1)};
`

export const Age = styled.p`
  ${props => props.theme.typography.sourceSansPro.font540};
  color: ${props => props.theme.color.darkGrey};
  line-height: 1;
  margin: 1.5rem auto 0;
  opacity: ${props => (props.greyed ? 0.3 : 1)};
`

export const Logo = styled(SDHLogoSvg)`
  width: 120px;
`

export const CreatorLogo = styled(CreatorLogoHorizontal)`
  width: 350px;
`

export const LogoText = styled.span`
  display: block;
  padding-right: 0.75rem;
  ${props => props.theme.typography.ttCommons.font160};
  color: ${props => props.theme.color.midGray};
`

export const LogoSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 2.5rem;
`
