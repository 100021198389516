import styled from "styled-components"

export const Subtitle = styled.h4`
  ${props => props.theme.typography.sourceSansPro.font260}
  color: ${props => props.theme.color.black};
  margin: 3rem 0 0;
  cursor: default;

  &:first-child {
    margin-top: 1rem;
  }
`

export const MultiValueWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const MultiValuePill = styled.div`
  border-radius: 1rem;
  margin: 0.5rem 0.75rem 0 0;
  padding: 0.25rem 1rem;
  ${props => props.theme.typography.sourceSansPro.font240}
  color: ${props => props.theme.color.white};
  background: ${({ variant, theme }) =>
    variant === "positive" ? theme.color.positiveTag : theme.color.negativeTag};
`
