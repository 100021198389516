import styled from "styled-components"
import CreatableSelect from "react-select/creatable"

export const TagAreaWrapper = styled.div`
  border: solid 1px ${props => props.theme.color.paleGrey};
  border-radius: 0.375rem;
  padding: 0.25rem;
  margin-bottom: 1.25rem;

  &:last-child {
    margin-bottom: 0;
  }
`

export const StyledCreatable = styled(CreatableSelect)`
  & div[class*="-control"] {
    border: none;
    box-shadow: none;
    cursor: pointer;

    &:focus,
    &:active {
      outline: none;
    }

    & > div:first-child {
      padding: 0;
    }
  }

  & div[class*="-IndicatorsContainer"] > div:first-child {
    padding: 0;
  }

  & div[class*="-IndicatorsContainer"] {
    padding: 0;
  }

  & div[class*="-multiValue"] {
    border-radius: 1rem;
    padding: 0.2rem 0.5rem;
    background: ${({ variant, theme }) =>
      variant === "positive"
        ? theme.color.positiveTag
        : theme.color.negativeTag};

    & > div:first-child {
      ${props => props.theme.typography.sourceSansPro.font340};

      color: ${props => props.theme.color.white};
      font-size: 0.875rem !important;
    }

    & > div:nth-child(2) {
      color: ${props => props.theme.color.white};
      opacity: 1;
      transition: opacity ${props => props.theme.animation.fast};
      background-color: transparent;
      padding: 0;

      &:hover {
        cursor: pointer;
        opacity: 0.7;
      }
    }
  }

  & div[class*="-Input"] {
    ${props => props.theme.typography.sourceSansPro.font260};
    color: ${props => props.theme.color.darkGrey};
  }

  & div[class*="-placeholder"] {
    display: none;
  }

  & input {
    ${props => props.theme.typography.sourceSansPro.font340};
    font-size: 0.875rem !important;
  }
`
