import React from "react"
import { Formik } from "formik"
import * as Yup from "yup"

import * as C from "shared/components"

import * as DMC from "./components"
import * as S from "./styled"

const DownloadModalSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Required"),
  fullname: Yup.string().required("Required"),
  company: Yup.string().required("Required"),
  role: Yup.string().required("Required"),
  isTermsAccepted: Yup.boolean().oneOf([true], "Required"),
})

const rolesOptions = [
  { id: 1, label: "CEO/Founder", value: "ceoFounder" },
  { id: 2, label: "CTO", value: "cto" },
  { id: 3, label: "Product owner/Head of product", value: "ownerHead" },
  { id: 4, label: "Developer/Engineer", value: "developerEngineer" },
  { id: 5, label: "Project Manager", value: "projectManager" },
  { id: 6, label: "Product Designer", value: "designer" },
  { id: 7, label: "Innovation Manager", value: "innovationManager" },
  { id: 8, label: "Other", value: "other" },
]

export const DownloadModal = ({
  onSubmit,
  downloadError,
  loading,
  success,
  ...props
}) => (
  <C.Modal {...props}>
    <S.DownloadModalContent>
      {success ? (
        <>
          <S.ThankYouImage />
          <S.ThankYouWrapper>
            <S.Title>Thank you for choosing Buyer Persona Creator</S.Title>
            <S.Description>
              Please check your email box and enjoy!
            </S.Description>
            <S.LogoSection>
              <S.LogoText>Powered by</S.LogoText>
              <S.ThankYouLogo />
            </S.LogoSection>
          </S.ThankYouWrapper>
        </>
      ) : (
        <>
          <S.DocumentsImage />
          <Formik
            initialValues={{
              email: "",
              fullname: "",
              role: "",
              company: "",
              isTermsAccepted: false,
            }}
            onSubmit={onSubmit}
            validationSchema={DownloadModalSchema}
          >
            {() => (
              <S.StyledForm loading={loading ? "loading" : undefined}>
                <S.Title>Download file</S.Title>
                <S.Description>
                  We will send you an email with your Persona attached in PDF
                  file. Please complete the form with your full name and email
                  address.
                </S.Description>
                <DMC.Input name="fullname" placeholder="Full name" />
                <S.SecondRowWrapper>
                  <DMC.Input isSelect name="role" options={rolesOptions} />
                  <DMC.Input name="company" placeholder="Company" />
                </S.SecondRowWrapper>
                <S.InputAndButtonWrapper>
                  <DMC.Input name="email" placeholder="Your e-mail" />
                  <C.Button
                    id="submit-newsletter-modal"
                    type="submit"
                    color="royalBlue"
                    size="xl"
                    overrides={{ width: "9rem" }}
                  >
                    Send
                  </C.Button>
                </S.InputAndButtonWrapper>
                <DMC.Checkbox
                  id="terms_checkbox"
                  name="isTermsAccepted"
                  label={
                    <S.CheckboxLabel>
                      I agree to receive the newsletter and commercial
                      information from Startup Development House{" "}
                      <a
                        href="https://staging.start-up.house/en/privacy-policy?utm_source=persona_creator"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Privacy&nbsp;policy
                      </a>
                    </S.CheckboxLabel>
                  }
                />
              </S.StyledForm>
            )}
          </Formik>
          <S.ProgressWrapper>{loading && <S.Progress />}</S.ProgressWrapper>
          <DMC.DownloadErrorMessage show={downloadError} />
        </>
      )}
    </S.DownloadModalContent>
  </C.Modal>
)
