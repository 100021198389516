import styled from "styled-components"

export const FileInput = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`

export const Label = styled.label`
  width: 7.5rem;
  height: 2.75rem;
  text-align: center;
  line-height: 2.75rem;
  font-family: ${props => props.theme.font.family.robotoMedium};
  font-size: ${props => props.theme.font.size.sm};
  color: ${props => props.theme.color.white};
  background-color: ${props => props.theme.color.royalBlue};
  display: inline-block;
  cursor: pointer;
  border-radius: 1.6rem;
  &:focus,
  &:hover {
    box-shadow: ${props => props.theme.boxShadow.largeButtonShadow};
  }
`

export const LabelInvisible = styled.label`
  width: 2.3rem;
  height: 2.3rem;
  opacity: 0;
  background-color: ${props => props.theme.color.royalBlue};
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
`
