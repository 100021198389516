import styled from "styled-components"

import DownloadIcon from "shared/assets/cloud-computing.svg"

export const TopSectionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1.4rem;
`

export const IconDownload = styled(DownloadIcon)`
  width: 1.2rem;
  height: 1.2rem;
  fill: ${props => props.theme.color.darkGray};
  margin-left: 0.875rem;
  vertical-align: middle;
`
