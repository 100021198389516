import React from "react"

import * as C from "shared/components"

import { BrandsTextarea } from "./BrandsTextarea"

export const ReachSection = ({ reach }) => {
  const options = reach.filter(r => r.name !== "brands")
  const brandsOptionIndex = reach.findIndex(r => r.name === "brands")

  return (
    <C.Card width="20rem" title="Reach" dotColor="pink" isPrintMode>
      {brandsOptionIndex === 0 && (
        <BrandsTextarea {...reach[brandsOptionIndex]} />
      )}
      {options.map(({ id, label, ...r }) => (
        <C.Slider key={id} {...r} name={label} isPrintMode />
      ))}
    </C.Card>
  )
}
