import styled from "styled-components"

export const CharacteristicsSectionRoot = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: space-between;
  flex-wrap: wrap;
  width: 41.875rem;
  height: 26.875rem;
  max-width: 66%;
`
