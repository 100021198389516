import styled from "styled-components"

export const H1 = styled.h1`
  font-family: ${props => props.theme.font.family.sourceSansProSemibold};;
  font-size: ${props => props.theme.font.size.xl};
  font-weight: ${props => props.theme.font.weight.bold};
  color: ${props => props.theme.color.white};
  line-height: 40px;
`

export const H2 = styled.h2`
  font-family: ${props => props.theme.font.family.sourceSansProRegular};
  font-size: ${props => props.theme.font.size.lg};
  font-weight: ${props => props.theme.font.weight.light};
  color: ${props =>
    props.color === "white" ? "white" : props.theme.color.darkGray};
  margin-bottom: 20px;
  line-height: 34px;
  text-align: ${props => (props.align === "center" ? "center" : "left")};
`

export const H3 = styled.h3`
  font-family: ${props => props.theme.font.family.latoBold};
  font-size: ${props => props.theme.font.size.base};
  letter-spacing: -.2px;
`

export const H4 = styled.h4`
  font-family: ${props => props.theme.font.family.latoBold};
  font-size: ${props => props.theme.font.size.sm};
  letter-spacing: -.18px;
`

export const DescriptionTitle = styled.p`
  font-family: ${props => props.theme.font.family.latoRegular};
  font-size: ${props => props.theme.font.size.base};
  color: ${props => props.theme.color.midGray};
  line-height: 16px;
`

export const Description = styled.p`
  font-family: ${props => props.theme.font.family.robotoRegular};
  font-size: ${props => props.theme.font.size.base};
  color: ${props => props.theme.color.darkGray};
  line-height: 16px;
  margin: 39px 0;
`

export const LogoDescription = styled.p`
  font-family: ${props => props.theme.font.family.robotoRegular};
  font-size: ${props => props.theme.font.size.sm};
  color: ${props => props.theme.color.midGray};
`