import React from "react"
import * as C from "shared/components"
import { exampleTrendString } from "components/persona/constants"
import * as S from "./styled"

export const TrendsSection = ({ positiveTrends, negativeTrends }) => {
  return (
    <C.Card width="20rem" title="Trends" dotColor="lavender" isPrintMode>
      <S.Subtitle>Negative trends</S.Subtitle>
      <S.MultiValueWrapper>
        {negativeTrends &&
          negativeTrends
            .filter(pt => !pt.value.includes(exampleTrendString))
            .map(nt => (
              <S.MultiValuePill key={nt.value}>{nt.value}</S.MultiValuePill>
            ))}
      </S.MultiValueWrapper>
      <S.Subtitle>Positive trends</S.Subtitle>
      <S.MultiValueWrapper>
        {positiveTrends &&
          positiveTrends
            .filter(pt => !pt.value.includes(exampleTrendString))
            .map(pt => (
              <S.MultiValuePill key={pt.value} variant="positive">
                {pt.value}
              </S.MultiValuePill>
            ))}
      </S.MultiValueWrapper>
    </C.Card>
  )
}
