import styled from "styled-components"

import AddIcon from "shared/assets/add-plus-button.svg"

const BOX_WIDTH = "13.5rem"
const BOX_HEIGHT = "13.1rem"

export const BoxContainer = styled.div`
  width: ${BOX_WIDTH};
  height: ${BOX_HEIGHT};
  position: relative;
  margin-left: 0.6rem;

  &:first-child,
  &:nth-child(4) {
    margin-left: 0;
  }
  &:not(:first-child):not(:nth-child(4)) {
    margin-left: 0.6rem;
    max-width: calc(33.33% - 0.6rem);
  }
`

export const NewBox = styled.button`
  position: absolute;
  background: transparent;
  border: 2px dashed ${props => props.theme.color.lightGrey};
  border-radius: 0.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  cursor: pointer;
  opacity: 1;
  transition: opacity ${props => props.theme.animation.fast};
  width: 100%;
  height: 100%;
  &:hover {
    opacity: 0.75;
  }
`
export const IconCircleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  &:before {
    content: "";
    width: 3.1rem;
    height: 3.1rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    z-index: 0;
    background-color: ${props => props.theme.color.midGray};
  }
`
export const IconAdd = styled(AddIcon)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0.75rem;
  height: 0.75rem;
  fill: ${props => props.theme.color.whiteSmoke};
`

export const NewBoxActionName = styled.p`
  ${props => props.theme.typography.sourceSansPro.font240};
  color: ${props => props.theme.color.darkGrey};
  margin: 0;
  position: absolute;
  top: calc(50% + 2.25rem);
  left: 50%;
  transform: translateX(-50%);
`

export const SelectBox = styled.div`
  position: absolute;
  width: ${BOX_WIDTH};
  height: ${BOX_HEIGHT};
  background: white;
  border-radius: 0.6rem;
  display: flex;
  flex-direction: column;
  box-shadow: ${props => props.theme.boxShadow.lightShadow};
  padding: 1rem;
`

export const SelectElement = styled.div`
  display: flex;
  align-items: center;
  padding: 0.2rem 0.3rem;
  &:hover {
    background-color: ${props => props.theme.color.paleGrey};
    border-radius: 0.3rem;
    cursor: pointer;
  }
`

export const SelectElementTitle = styled.h4`
  ${props => props.theme.typography.sourceSansPro.font240};
  color: ${props => props.theme.color.darkGrey};
  padding-left: 1.25rem;
  position: relative;

  &:before {
    content: "";
    display: block;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 0.75rem;
    height: 0.75rem;
    background-color: ${props => props.theme.color[props.dotColor]};
  }
`
