import React from "react"

import { FileInput, Label } from "./styled"

export const Upload = ({ onChange }) => {
  return (
    <>
      <FileInput type="file" name="file" id="file" onChange={onChange} />
      <Label htmlFor="file">Upload</Label>
    </>
  )
}
