import styled from "styled-components"

import DocumentsImageSvg from "shared/assets/pdf-modal-icon.svg"
import ThankYouImageSvg from "shared/assets/thank_you.svg"
import SDHLogoSvg from "shared/assets/sdh-logo.svg"
import { theme } from "shared/styles/theme"
import { Form } from "formik"

const { breakpoints } = theme

export const DownloadModalContent = styled.div`
  padding: 2rem;
  max-width: 37.5rem;

  @media (max-width: ${breakpoints.xl}px) {
    padding: 1rem;
    & input,
    & select,
    & button {
      padding: 0 1rem;
      height: 3rem;
      line-height: 3rem;
      ${props => props.theme.typography.sourceSansPro.font340};
    }

    & select + span {
      top: 1.35rem;
    }
  }
`

export const StyledForm = styled(Form)`
  opacity: ${props => (props.loading ? 0.4 : 1)};
  transition: opacity 0.3s;
  padding: 1.5rem;

  @media (max-width: ${breakpoints.xl}px) {
    padding: 0.5rem;
  }
`

export const DocumentsImage = styled(DocumentsImageSvg)`
  display: block;
  width: 16.625rem;
  height: 14.25rem;
  margin: -7.5rem auto 0;

  @media (max-width: ${breakpoints.xl}px) {
    width: 9.625rem;
    height: 7.25rem;
    margin: -2.5rem auto 0;
  }
`

export const ThankYouImage = styled(ThankYouImageSvg)`
  display: block;
  width: 20.625rem;
  height: 18.25rem;
  margin: -5.5rem auto 0;
`

export const ThankYouLogo = styled(SDHLogoSvg)`
  width: 120px;
`

export const LogoText = styled.span`
  display: block;
  padding-right: 0.75rem;
  ${props => props.theme.typography.ttCommons.font160};
  color: ${props => props.theme.color.midGray};
`

export const ThankYouWrapper = styled.div`
  padding: 1.5rem;
  padding-bottom: 0;
  text-align: center;
`

export const LogoSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Title = styled.h2`
  ${props => props.theme.typography.sourceSansPro.font560};
  color: ${props => props.theme.color.darkGray};
  text-align: center;
  margin: 2rem auto 1.25rem;

  @media (max-width: ${breakpoints.xl}px) {
    ${props => props.theme.typography.sourceSansPro.font460};
    margin: 1rem auto 0.5rem;
  }
`

export const Description = styled.p`
  ${props => props.theme.typography.sourceSansPro.font440};
  color: ${props => props.theme.color.darkGreyBlue};
  margin: 0 auto 3rem;

  @media (max-width: ${breakpoints.xl}px) {
    ${props => props.theme.typography.sourceSansPro.font340};
    margin: 0 auto 1rem;
  }
`

export const InputAndButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  & > div:first-child {
    flex-grow: 1;
    margin-right: 1.25rem;
  }
`

export const SecondRowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  & > div:first-child {
    width: 60%;
    padding-right: 1rem;
  }

  & > div:last-child {
    width: 40%;
  }
`

export const DownloadErrorMessage = styled.div`
  background-color: rgba(255, 0, 0, 0.1);
  border-radius: 4px;
  padding: 8px 12px;
  & svg {
    width: 16px;
    height: 16px;
  }
`

export const ProgressWrapper = styled.div`
  position: relative;
`

export const Progress = styled.progress`
  position: absolute;
  top: 0;
  left: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  height: 0.25em;
  width: 100%;
  color: rgb(var(--pure-material-primary-rgb, 33, 150, 243));
  background-color: rgba(var(--pure-material-primary-rgb, 33, 150, 243), 0.12);
  font-size: 16px;

  &::-webkit-progress-bar {
    background-color: transparent;
  }

  /* Determinate */
  &::-webkit-progress-value {
    background-color: currentColor;
    transition: all 0.2s;
  }

  &::-moz-progress-bar {
    background-color: currentColor;
    transition: all 0.2s;
  }

  &::-ms-fill {
    border: none;
    background-color: currentColor;
    transition: all 0.2s;
  }

  /* Indeterminate */
  &:indeterminate {
    background-size: 200% 100%;
    background-image: linear-gradient(
      to right,
      transparent 50%,
      currentColor 50%,
      currentColor 60%,
      transparent 60%,
      transparent 71.5%,
      currentColor 71.5%,
      currentColor 84%,
      transparent 84%
    );
    animation: pure-material-progress-linear 2s infinite linear;
  }

  &:indeterminate::-moz-progress-bar {
    background-color: transparent;
  }

  &:indeterminate::-ms-fill {
    animation-name: none;
  }

  @keyframes pure-material-progress-linear {
    0% {
      background-size: 200% 100%;
      background-position: left -31.25% top 0%;
    }
    50% {
      background-size: 800% 100%;
      background-position: left -49% top 0%;
    }
    100% {
      background-size: 400% 100%;
      background-position: left -102% top 0%;
    }
  }
`
export const CheckboxLabel = styled.span`
  display: inline-block;
  max-width: 400px;
`
