import React from "react"
import { FastField } from "formik"

import * as S from "./styled"

export const TitleInput = props => (
  <S.TitleInput>
    <FastField name="title">
      {({ field, form }) => {
        const error = form.errors[field.name]

        const onBlur = () => {
          if (field.value === "") {
            form.setFieldError(field.name, "Required")
            return
          }

          form.submitForm()
        }

        return (
          <>
            <S.StyledInput
              placeholder="Title of new persona"
              data-lpignore="true"
              isError={!!error}
              {...field}
              {...props}
              onBlur={onBlur}
            />
            <S.ErrorLabel>{error}</S.ErrorLabel>
          </>
        )
      }}
    </FastField>
  </S.TitleInput>
)
